<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pelanggan</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-7">
                <h5>Informasi Pelanggan</h5>
                <div class="alert alert-info fade show f-s-13">
                  <table class="table">
                    <tr>
                      <td>Nama</td>
                      <td style="width: 10px">:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.customer_name"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>NIK / NIB / No. Referensi</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.identity_number"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>NPWRD</td>
                      <td>:</td>
                      <td>
                        <LazyLoad :data-to-display="detail.npwrd"></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>Nomor HP/WA</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.phone_number"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>Alamat</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.customer_address"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>Kecamatan</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.district_name"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>Kelurahan</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.village_name"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>RW/RT</td>
                      <td>:</td>
                      <td>
                        <LazyLoad :data-to-display="rwrtData"></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>Jenis Kegiatan</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.category_name"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr>
                      <td>Satuan</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="this.currencyFormat(detail.cost)"
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr v-if="detail.created_at">
                      <td>Tanggal Diinput</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="
                            this.timeStampFormat(detail.created_at)
                          "
                        ></LazyLoad>
                      </td>
                    </tr>
                    <tr v-if="detail.created_at">
                      <td>Diinput Oleh</td>
                      <td>:</td>
                      <td>
                        <LazyLoad
                          :data-to-display="detail.created_name"
                        ></LazyLoad>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="mt-4 mb-4" v-if="detail.latitude != 0">
                  <h5>Titik Lokasi</h5>
                  <l-map
                    :zoom="zoom"
                    :center="center"
                    style="height: 300px; width: 100%; border-radius: 10px"
                  >
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <l-marker :lat-lng="markerLatLng">
                      <l-popup>
                        {{ detail.customer_name }}
                        <br />
                        {{ detail.customer_address }}
                      </l-popup>
                    </l-marker>
                  </l-map>
                </div>
                <div v-if="detail.image_house">
                  <h5>Foto Depan Rumah</h5>
                  <img
                    :src="detail.image_house"
                    style="width: 100%; border-radius: 10px"
                  />
                </div>
              </div>

              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-12 mb-4">
                    <h5>Kartu Pelanggan</h5>
                    <canvas ref="myCanvas" style="display: none"></canvas>
                    <img
                      class="skeleton-loader"
                      ref="canvasImage"
                      width="100%"
                      style="border-radius: 10px"
                    />
                    <button
                      class="btn btn-warning btn-block mt-1"
                      @click="downloadImage"
                    >
                      Download Kartu
                    </button>
                  </div>
                  <div class="col-md-12 mb-4">
                    <h5>Status Transaksi</h5>
                    <div class="bg-green-transparent-1 rounded p-5">
                      <table class="table table-bordered">
                        <tr>
                          <td class="text-center">Jan</td>
                          <td class="text-center">Feb</td>
                          <td class="text-center">Mar</td>
                          <td class="text-center">Apr</td>
                          <td class="text-center">Mei</td>
                          <td class="text-center">Jun</td>
                        </tr>
                        <tr>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.jan == '1',
                                'btn-danger':
                                  payment.jan == '0' || payment.jan == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.jan == '1',
                                  'fa fa-times':
                                    payment.jan == '0' || payment.jan == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.feb == '1',
                                'btn-danger':
                                  payment.feb == '0' || payment.feb == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.feb == '1',
                                  'fa fa-times':
                                    payment.feb == '0' || payment.feb == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.mar == '1',
                                'btn-danger':
                                  payment.mar == '0' || payment.mar == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.mar == '1',
                                  'fa fa-times':
                                    payment.mar == '0' || payment.mar == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.apr == '1',
                                'btn-danger':
                                  payment.apr == '0' || payment.apr == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.apr == '1',
                                  'fa fa-times':
                                    payment.apr == '0' || payment.apr == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.mei == '1',
                                'btn-danger':
                                  payment.mei == '0' || payment.mei == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.mei == '1',
                                  'fa fa-times':
                                    payment.mei == '0' || payment.mei == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.jun == '1',
                                'btn-danger':
                                  payment.jun == '0' || payment.jun == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.jun == '1',
                                  'fa fa-times':
                                    payment.jun == '0' || payment.jun == null,
                                }"
                              ></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6"></td>
                        </tr>
                        <tr>
                          <td class="text-center">Jul</td>
                          <td class="text-center">Ags</td>
                          <td class="text-center">Sep</td>
                          <td class="text-center">Okt</td>
                          <td class="text-center">Nov</td>
                          <td class="text-center">Des</td>
                        </tr>
                        <tr>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.jul == '1',
                                'btn-danger':
                                  payment.jul == '0' || payment.jul == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.jul == '1',
                                  'fa fa-times':
                                    payment.jul == '0' || payment.jul == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.aug == '1',
                                'btn-danger':
                                  payment.aug == '0' || payment.aug == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.aug == '1',
                                  'fa fa-times':
                                    payment.aug == '0' || payment.aug == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.sep == '1',
                                'btn-danger':
                                  payment.sep == '0' || payment.sep == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.sep == '1',
                                  'fa fa-times':
                                    payment.sep == '0' || payment.sep == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.okt == '1',
                                'btn-danger':
                                  payment.okt == '0' || payment.okt == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.okt == '1',
                                  'fa fa-times':
                                    payment.okt == '0' || payment.okt == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.nov == '1',
                                'btn-danger':
                                  payment.nov == '0' || payment.nov == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.nov == '1',
                                  'fa fa-times':
                                    payment.nov == '0' || payment.nov == null,
                                }"
                              ></i>
                            </a>
                          </td>
                          <td class="text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-icon btn-circle"
                              :class="{
                                'btn-success': payment.des == '1',
                                'btn-danger':
                                  payment.des == '0' || payment.des == null,
                              }"
                            >
                              <i
                                :class="{
                                  'fa fa-check': payment.des == '1',
                                  'fa fa-times':
                                    payment.des == '0' || payment.des == null,
                                }"
                              ></i>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="historyPayment.length > 0">
                    <h5>Riwayat Transaksi</h5>
                    <div class="bg-yellow-transparent-1 rounded p-5">
                      <div
                        v-for="history in historyPayment"
                        :key="history.transaction_id"
                        class="d-flex align-items-center m-b-15"
                      >
                        <div class="text-truncate">
                          <div>{{ history.additional_details }}</div>
                          <div class="text-grey">
                            {{ indonesianFormat(history.transaction_date) }}
                          </div>
                        </div>
                        <div class="ml-auto text-center">
                          <div class="f-s-13">
                            <span
                              data-animation="number"
                              :data-value="history.payment_amount"
                              >{{
                                currencyFormat(history.payment_amount)
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../plugins/sipData";
import moment from "moment";
import LazyLoad from "../../components/loader/LazyLoad.vue";
import QRCode from "qrcode";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  mixins: [sipData],
  components: {
    LazyLoad,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      detail: {
        customer_id: "",
        npwrd: "",
        identity_number: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        created_name: "",
        image_house: "",
        longitude: "",
        latitude: "",
        phone_number: "",
      },
      payment: {
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        mei: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        okt: "",
        nov: "",
        des: "",
      },
      historyPayment: [],
      loading: false,
      showUserAccess: true,
      zoom: 13,
      center: [-0.8967894835310088, 119.86881906647227],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
      markerLatLng: [-0.8967894835310088, 119.86881906647227],
    };
  },
  computed: {
    rwrtData() {
      return `${this.detail.hamlet} / ${this.detail.neighbourhood}`;
    },
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.setIconMarker();
    const id = this.$route.params.id;
    if (id) {
      this.detail.customer_id = id;
      this.fetchData();
    }
  },
  methods: {
    contentLoaded() {
      this.loading = false;
    },
    fetchData() {
      axios
        .get("/v1/customers/detail/" + this.detail.customer_id)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          if (this.detail.latitude != 0) {
            this.center = [this.detail.latitude, this.detail.longitude];
            this.markerLatLng = [this.detail.latitude, this.detail.longitude];
          }
          this.historyPayment = response.data.history;
          this.payment = response.data.payment;
          if (this.detail.npwrd) {
            this.drawCanvas();
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    downloadImage() {
      const canvas = this.$refs.myCanvas;
      const dataURL = canvas.toDataURL("image/jpeg");

      // Membuat elemen <a> untuk mendownload gambar
      const downloadLink = document.createElement("a");
      downloadLink.href = dataURL;
      downloadLink.download = "kartu-retribusi-" + this.detail.npwrd + ".jpg";

      // Simulasikan klik pada elemen <a> untuk memulai pengunduhan
      downloadLink.click();
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    paymentStatus(param) {
      if (param == 1) {
        return "<i class='fa fa-check-circle'></i>";
      } else {
        return "Belum Lunas";
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    drawCanvas() {
      const canvas = this.$refs.myCanvas;
      const context = canvas.getContext("2d");
      const dpi = window.devicePixelRatio || 1;

      // Mengatur lebar dan tinggi canvas sesuai dengan dimensi fisik
      canvas.width = 2510 * dpi; // Lebar dalam piksel
      canvas.height = 1476 * dpi; // Tinggi dalam piksel

      context.imageSmoothingQuality = "high";

      // Gambar latar belakang
      context.fillStyle = "#f0f0f0";
      context.fillRect(0, 0, canvas.width, canvas.height);

      const blanko = new Image();
      blanko.src = require("@/assets/blanko.png");

      blanko.onload = () => {
        context.drawImage(blanko, 0, 0, canvas.width, canvas.height);

        const sairaFont = new FontFace(
          "Saira",
          `url(${require("@/assets/font/saira.ttf")})`
        );

        sairaFont.load().then((font) => {
          document.fonts.add(font);

          // Menghasilkan Promise untuk QR code data URL
          const qrCodePromise = QRCode.toDataURL(this.detail.npwrd);

          // Menunggu hingga Promise selesai
          qrCodePromise
            .then((qrCodeUrl) => {
              // Membuat objek Image
              const qrCodeImage = new Image();
              qrCodeImage.src = qrCodeUrl;

              // Menunggu hingga gambar QR code selesai dimuat
              qrCodeImage.onload = () => {
                // Menggambar gambar ke dalam canvas setelah QR code selesai dimuat
                context.drawImage(
                  qrCodeImage,
                  1845 * dpi,
                  650 * dpi,
                  510 * dpi,
                  510 * dpi
                );

                // Fungsi untuk mengatur teks secara otomatis menyesuaikan atau "wrap"
                const wrapText = (text, x, y, maxWidth, lineHeight) => {
                  let words = text.split(" ");
                  let line = "";
                  let yPos = y;

                  for (let i = 0; i < words.length; i++) {
                    let testLine = line + words[i] + " ";
                    let metrics = context.measureText(testLine);
                    let testWidth = metrics.width;

                    if (testWidth > maxWidth && i > 0) {
                      context.fillText(line, x, yPos);
                      line = words[i] + " ";
                      yPos += lineHeight;
                    } else {
                      line = testLine;
                    }
                  }

                  context.fillText(line, x, yPos);
                };

                // Mengatur teks secara otomatis menyesuaikan atau "wrap"
                context.font = `bold ${100 * dpi}px Saira`;
                context.fillStyle = "#F5661A";
                wrapText(
                  this.detail.customer_name,
                  150 * dpi,
                  1070 * dpi,
                  1700 * dpi, // Lebar maksimum untuk wrapping teks
                  120 * dpi // Tinggi baris teks
                );

                context.font = `bold ${250 * dpi}px Saira`;
                context.fillStyle = "#274665";
                wrapText(
                  this.detail.npwrd,
                  150 * dpi,
                  920 * dpi,
                  2200 * dpi, // Lebar maksimum untuk wrapping teks
                  300 * dpi // Tinggi baris teks
                );

                context.font = `bold ${80 * dpi}px Saira`;
                context.fillStyle = "#274665";
                wrapText(
                  this.timeStampFormat(this.detail.created_at),
                  150 * dpi,
                  1335 * dpi,
                  2200 * dpi, // Lebar maksimum untuk wrapping teks
                  90 * dpi // Tinggi baris teks
                );

                const canvasImage = this.$refs.canvasImage;

                const dataURL = canvas.toDataURL("image/jpg");

                canvasImage.src = dataURL;
              };
            })
            .catch((error) => {
              console.error("Gagal menghasilkan QR code:", error);
            });
        });
      };
    },
    setIconMarker() {
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Saira";
  src: url("../../assets/font/saira.ttf") format("truetype");
}
.skeleton-loader {
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 10px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
